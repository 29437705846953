import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <Auth0Provider
  //   domain="dev-8p6c8g3ktchsc5w8.us.auth0.com"
  //   clientId="lZE1bD0jYoqnz2A66PhEBxujvByVZkln"
  //   authorizationParams={{
  //     redirect_uri: window.location.origin
  //   }}
  // >
    <App />
  // </Auth0Provider>  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
